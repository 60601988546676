<template>
    <v-layout row justify-center>
        <v-dialog v-model="addItem" persistent scrollable max-width="500px">
            <v-card>
                <v-card-title>
                    <span class="headline">Transaction Item</span>
                    <v-spacer></v-spacer>
                    <!--<v-tooltip bottom> 
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" @click.prevent="createNewItem = true"
                                   slot="activator"
                                   small
                                   fab
                                   text
                                   middle
                                   right>
                                <v-icon>fas fa-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>Add Item</span>
                    </v-tooltip>-->
                    <v-btn icon @click.native="addItemCheck($v.itemTemp)">
                        <v-icon color="green">fas fa-check</v-icon>
                    </v-btn>
                    <v-btn icon @click.native="closeView()">
                        <v-icon color="red">fas fa-times</v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex xs11>
                                <v-autocomplete v-model="itemTemp.itemName"
                                                :items="items"
                                                solo
                                                label="Item Name"
                                                :error-messages="ItemNameErrors"
                                                @input="$v.itemTemp.itemName.$touch()"
                                                @blur="$v.itemTemp.itemName.$touch()"
                                                clearable
                                                item-text="itemName"
                                                item-value="itemName"
                                                style="padding-top:30px;">
                                    <template slot="selection"
                                              slot-scope="data">
                                        {{ data.item.itemName }}
                                    </template>
                                    <template slot="item"
                                              slot-scope="data">

                                        <template>
                                            <v-list-item-content>
                                                <v-list-item-title v-html="data.item.itemName"></v-list-item-title>
                                                <v-list-item-subtitle>{{data.item.currency}} {{data.item.itemPrice}}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </template>
                                    </template>
                                    <!--<template slot="no-data">
                                        <template>
                                            <v-btn @click.prevent="createNewItem = true"
                                                   small
                                                   block
                                                   outlined
                                                   text
                                                   color="green"
                                                   middle
                                                   right>
                                                <v-icon left>fas fa-plus</v-icon>
                                                <span>Create New Item</span>
                                            </v-btn>
                                        </template>
                                    </template>-->
                                </v-autocomplete>
                            </v-flex>
                            <v-flex xs1 class="d-flex align-center">
                                <v-btn @click.prevent="createNewItem = true"
                                       small
                                       fab
                                       icon
                                       color="green">
                                    <v-icon>fas fa-plus</v-icon>
                                </v-btn>
                            </v-flex>
                            <v-flex xs6>
                                <v-text-field label="Item Price" type="number" min="1"
                                              v-model="itemTemp.itemPrice"
                                              :error-messages="ItemPriceErrors"
                                              @input="$v.itemTemp.itemPrice.$touch(), updateTotals()"
                                              @blur="$v.itemTemp.itemPrice.$touch()"></v-text-field>
                            </v-flex>
                            <!--<v-flex xs6>
                                <v-autocomplete v-bind:items="currencies"
                                                label="Currency"
                                                v-model="itemTemp.currency"
                                                :error-messages="currencyErrors"
                                                @input="$v.itemTemp.currency.$touch()"
                                                @blur="$v.itemTemp.currency.$touch()"
                                                item-text="currencyName"
                                                item-value="currencyShort">
                                    <template slot="selection"
                                              slot-scope="data">
                                        <v-chip :input-value="data.selected"
                                                class="chip--select-multi">
                                            {{ data.item.currencyShort }}
                                        </v-chip>
                                    </template>
                                    <template slot="item"
                                              slot-scope="data">

                                        <template>
                                            <v-list-item-content>
                                                <v-list-item-title v-html="data.item.currencyName"></v-list-item-title>
                                                <v-list-item-subtitle v-html="data.item.currencyShort"></v-list-item-subtitle>
                                            </v-list-item-content>
                                        </template>
                                    </template>
                                </v-autocomplete>
                            </v-flex>-->
                            <v-flex xs6>
                                <v-text-field label="Quantity" type="number"
                                              v-model="itemTemp.quantity"
                                              :error-messages="ItemQuantityErrors"
                                              @input="$v.itemTemp.quantity.$touch(), updateTotals()"
                                              @blur="$v.itemTemp.quantity.$touch()"></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-checkbox label="Add Tax to item?"
                                            v-model="itemTemp.isTaxed"
                                            @change="updateTotals()"></v-checkbox>
                            </v-flex>
                            <v-flex xs12 v-if="itemTemp.isTaxed">
                                <v-text-field label="Tax" type="number"
                                              v-model="itemTemp.taxAmount"></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field label="Sub-Total" type="number"
                                              v-model="itemTemp.subtotal"></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field label="Total" type="number"
                                              v-model="itemTemp.total   "></v-text-field>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card-text>

            </v-card>
        </v-dialog>

        <AddItem v-if="createNewItem" 
                 v-on:itemShow="closeNewItem($event)"
                 v-on:newItem="addNewItem($event)"></AddItem>
    </v-layout>
</template>

<script>
    import { validationMixin } from 'vuelidate'
    import { required, minValue } from 'vuelidate/lib/validators'

    import { globalFunctions } from '../../javascript/globalFunctions'

    export default {
        components: {
            AddItem: () => import('../items/addItems.vue')
        },
        mixins: [validationMixin],
        props: ["itype", "itemIds", "show", "currency", "placeSold"],
        data() {
            return {
                currencies: [],
                addItem: true,
                createNewItem: false,
                editItems: false,
                deleteDialog: false,
                ItemsNames: [],
                ItemsSearch: '',
                itemNameMain: false,
                itemTemp: {
                    id: 0,
                    itemName: '',
                    itemPrice: '',
                    currency: '',
                    category: '',
                    quantity:'',
                    isTaxed: false,
                    taxAmount: '',
                    subtotal: '',
                    total:'',
                    dateChanged: '',
                    placeSold: '',

                    objectState: 'New',
                    show: false
                },
                newItem: {
                    id: 0,
                    itemName: '',
                    itemPrice: '',
                    currency: '',
                    category: '',
                    quantity: '',
                    isTaxed: false,
                    taxAmount: '',
                    subtotal: '',
                    total: '',
                    dateChanged: '',

                    objectState: 'New',
                    show: false
                },
                items: [],
                itemsMinusDeleted: [],
                showItems: []
            }
        },
        computed: {
            ItemNameErrors() {
                const errors = []
                if (!this.$v.itemTemp.itemName.$dirty) return errors
                !this.$v.itemTemp.itemName.required && errors.push('Item Name is required')
                return errors
            },
            ItemPriceErrors() {
                const errors = []
                if (!this.$v.itemTemp.itemPrice.$dirty) return errors
                !this.$v.itemTemp.itemPrice.minValue && errors.push('Item Price must be at least 1')
                return errors
            },
            currencyErrors() {
                const errors = []
                if (!this.$v.itemTemp.currency.$dirty) return errors
                !this.$v.itemTemp.currency.required && errors.push('Currency is required')
                return errors
            },
            ItemQuantityErrors() {
                const errors = []
                if (!this.$v.itemTemp.quantity.$dirty) return errors
                !this.$v.itemTemp.quantity.required && errors.push('Quantity is required')
                return errors
            }
        },
        methods: {
            itemCheck: function ($v) {
                $v.$touch(); //Triggers Error Messages

                if (this.itemTemp.itemPrice < 0 || !globalFunctions.isIntOrFloat(this.itemTemp.itemPrice) || this.itemTemp.itemPrice === "") { this.itemTemp.itemPrice = 0 }

                if ($v.itemName.$invalid || $v.itemPrice.$invalid || $v.currency.$invalid) {
                    return false
                }
                return true
            },
            addItemCheck: function ($v) {
                if (this.itemCheck($v)) {
                    this.addItemToItems()

                    $v.$reset()
                }
            },
            addItemToItems: function () {
                //Add Item to Transaction Items

                this.convertTempItemToNewItem()

                let newItem = globalFunctions.stringify(this.newItem)

                newItem.id = globalFunctions.getNextIdForObject(this.items)

                //this.showItems.push(newItem)

                this.addItem = false

                //this.doesItemExist()

                this.returnItem(newItem)

                this.resetItemForm()

                this.closeView()
            },
            convertTempItemToNewItem: function () {
                this.newItem.itemName = this.itemTemp.itemName
                this.newItem.itemPrice = this.itemTemp.itemPrice
                this.newItem.currency = this.itemTemp.currency
                this.newItem.quantity = this.itemTemp.quantity
                this.newItem.isTaxed = this.itemTemp.isTaxed
                this.newItem.taxAmount = this.itemTemp.taxAmount
                this.newItem.subtotal = this.itemTemp.subtotal
                this.newItem.total = this.itemTemp.total
            },
            resetItemForm: function () {
                this.itemTemp.itemName = ''
                this.itemTemp.itemPrice = 0
                this.itemTemp.currency = ''
                this.itemTemp.quantity = ''
                this.itemTemp.isTaxed = false
                this.itemTemp.taxAmount = 0
            },
            //doesItemExist: function () {
            //    if (this.items.length !== 0) {
            //        for (let i = 0; i < this.items.length; i++) {
            //            if (this.items[i].itemName === this.itemTemp.itemName) {

            //                /////////////////////////////////////////////////////////////
            //                //Check All other values to see if they are the same
            //                /////////////////////////////////////////////////////////////

            //                if (this.items[i].itemPrice !== this.itemTemp.itemPrice) {

            //                    ///////////////////////////////////////////////////////////////////
            //                    //Create New Item To Represent Update,
            //                    //Keep Old Data As To Not Cause Problems To Past Transactions
            //                    ///////////////////////////////////////////////////////////////////

            //                    this.addNewItem()
            //                    return
            //                }
            //                else if (this.items[i].itemPrice === this.itemTemp.itemPrice && this.items[i].currency === this.itemTemp.currency) {
            //                    return
            //                }

            //            }

            //            if (i === (this.items.length - 1)) {
            //                this.addNewItem()
            //                return
            //            }
            //        }
            //    }
            //    else {
            //        this.addNewItem()
            //    }

            //},
            //addNewItem: function () {
            //    this.itemTemp.dateChanged = globalFunctions.getCurrentDate()

            //    let newEntry = globalFunctions.stringify(this.itemTemp)

            //    newEntry.id = globalFunctions.getNextIdForObject(this.items)

            //    this.$store.commit('addNewItemToStore', newEntry)
            //},
            returnItem: function (item) {
                this.$emit('ItemToAdd', item)
            },
            returnPlaceSold: function () {
                let place = this.itemTemp.placeSold

                this.$emit('getPlaceSold', place)
            },
            getAvailableCurriencies: function () {
                let cur = this.$store.state.Currencies

                for (let i = 0; i < cur.length; i++) {
                    if (cur[i].ObjectState !== "Delete") {
                        this.currencies.push(cur[i])
                    }
                }
            },
            returnTaxPercentage: function () {
                for (let i = 0; i < this.currencies.length; i++) {
                    if (this.currencies[i].currencyShort === this.itemTemp.currency) {
                        return this.currencies[i].gct
                    }
                    if (i == this.currencies.length) {
                        return 0
                    }
                }
            },
            closeNewItem: function (item) {
                this.createNewItem = item
            },
            addNewItem: function (item) {
                //Update Items List
                this.items = this.$store.state.Items

                this.itemTemp.itemName = item.itemName
            },
            closeView: function () {
                this.$emit('show', false)
            },
            updateTotals: function () {
                this.checkQuantity()
                this.calculateSubTotal()
                this.calculateTax()
                this.calculateTotal()
            },
            calculateTax: function () {
                if (this.itemTemp.isTaxed) {
                    this.itemTemp.taxAmount = ((parseFloat(this.returnTaxPercentage()) / 100) * parseFloat(this.itemTemp.subtotal)).toFixed(2)
                }
            },
            checkQuantity: function () {
                if (globalFunctions.isIntOrFloat(this.itemTemp.itemPrice) && !globalFunctions.isIntOrFloat(this.itemTemp.quantity)) {
                    this.itemTemp.quantity = 1
                }
            },
            calculateSubTotal: function () {
                this.itemTemp.subtotal = parseFloat(this.itemTemp.quantity) * parseFloat(this.itemTemp.itemPrice)
            },
            calculateTotal: function () {

                if (globalFunctions.isIntOrFloat(this.itemTemp.taxAmount) && this.itemTemp.isTaxed) {
                    this.itemTemp.total = parseFloat(this.itemTemp.subtotal) + parseFloat(this.itemTemp.taxAmount)
                } else {
                    this.itemTemp.total = parseFloat(this.itemTemp.subtotal)
                }
            }
        },
        watch: {
            "itemTemp.itemName": function () {
                for (let i = 0; i < this.items.length; i++) {
                    if (this.itemTemp.itemName === this.items[i].itemName) {
                        this.itemTemp.itemPrice = this.items[i].priceList[0].itemPrice
                        this.itemTemp.currency = this.items[i].currency
                        this.itemTemp.category = this.items[i].category
                        this.itemTemp.isTaxed = this.items[i].isTaxed
                        this.itemTemp.taxAmount = this.items[i].taxAmount
                        this.itemTemp.placeSold = this.items[i].placeSold

                        break
                    }
                }
                this.updateTotals()
                this.returnPlaceSold()
            }
        },
        beforeMount() {
            this.items = this.$store.state.Items

            this.getAvailableCurriencies()

            this.addItem = true

            this.itemTemp.currency = this.currency
        },
        validations: {
            itemTemp: {
                itemName: {
                    required
                },
                itemPrice: {
                    required,
                    minValue: minValue(1)
                },
                currency: {
                    required
                },
                quantity: {
                    required
                }
            }            
        }
    }
</script>